import { errorResponse } from './response'

const messageApi = ($axios) => {
  const http = $axios
  return {
    /**
     * Send message to Admin
     *
     * @param {message, recipientId, title, sendTo (user/admin) } data
     * @returns Promise<req>
     */
    async send({ message, recipientId = '', disputeId = '', title, sendTo }) {
      let payload = {}
      if (disputeId === '') {
        payload = { message, recipientId, title, sendTo }
      } else {
        payload = { message, recipientId, title, sendTo, disputeId }
      }
      try {
        const req = await http.post('/messages', payload)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async get({ type = 'all', messageId = '' }, params) {
      try {
        const req = await http.get(
          `/${type === 'all' ? 'messages' : `messages/${messageId}`}`,
          {
            params,
          }
        )
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async markRead({ messageId = '' }) {
      try {
        const req = await http.post('/messages/mark-read', {
          messageIds: [messageId],
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
  }
}

export default messageApi
