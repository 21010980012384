import { errorResponse } from './response'

const disputeAPi = ($axios) => {
  const http = $axios
  return {
    async create({
      respondentEmail,
      respondentName,
      description,
      // eslint-disable-next-line no-unused-vars
      type = '',
      costImplication,
      title = '',
      files,
    }) {
      const data = {
        respondentEmail,
        respondentName,
        description,
        costImplication,
        files,
        title,
      }
      const formData = new FormData()
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key]
          if (Array.isArray(element)) {
            element.forEach((value) => {
              formData.append('files', value)
            })
          } else {
            formData.append(key, element)
          }
        }
      }
      try {
        const req = await http.post('/disputes', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    // my-complaints,my-responses
    async getAll({ params = {}, type = 'my-complaints' }) {
      try {
        const req = await http.get(`/disputes/${type}`, {
          params,
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getAllNoUser({ params = {} }) {
      try {
        const req = await http.get(`/disputes/`, {
          params,
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async get({ disputeId, params }) {
      try {
        const req = await http.get(`/disputes/${disputeId}`, {
          params,
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async updateDispute({ disputeId, data }) {
      const formData = new FormData()
      delete data.files
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key]
          formData.append(key, element)
        }
      }
      try {
        const req = await http.get('/disputes', { params: { disputeId } })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    /**
     * Resolve or unresolved a dispute
     * status - resolved/unresolved
     *
     * @param {*} param0
     * @returns
     */
    async resolveDispute({
      disputeId,
      status,
      initiatorComment = '',
      respondentComment = '',
    }) {
      try {
        const req = await http.post(`/disputes/${disputeId}/resolve`, {
          status,
          initiatorComment,
          respondentComment,
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async currentDisputeResolution({ disputeId }) {
      try {
        const req = await http.get(
          `/disputes/${disputeId}/get-current-resolution`
        )
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async updateCurrentDisputeResolution({
      disputeId,
      respondentComment,
      status,
    }) {
      try {
        const req = await http.post(
          `/disputes/${disputeId}/update-current-resolution`,
          { status, respondentComment }
        )
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async requestMediator({ disputeId }) {
      try {
        const req = await http.get(`/disputes/${disputeId}/get-mediation`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async currentMediatorStatus({ disputeId }) {
      try {
        const req = await http.get(
          `/disputes/${disputeId}/get-current-mediation`
        )
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async updateCurrentMediatorStatus({ disputeId, status, selections }) {
      try {
        const req = await http.post(
          `/disputes/${disputeId}/update-current-mediation`,
          { status, selections }
        )
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
  }
}

export default disputeAPi
