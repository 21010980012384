<template>
  <div>
    <TabHeaders>
      View Dispute
      <template #text> </template>
    </TabHeaders>

    <a-drawer
      title="Information about Dispute"
      placement="right"
      :width="width > '640' ? 420 : 320"
      :visible="disputeDrawerVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="disputeDrawerVisible = false"
    >
      <div>
        <h2 class="text-base">Title</h2>
        <p class="text-sm text-gray-600">
          {{ disputeData.title }}
        </p>
      </div>
      <div>
        <h2 class="text-base">Description</h2>
        <p class="text-sm text-gray-600">
          {{ disputeData.description }}
        </p>
      </div>
      <div>
        <h2 class="text-base">Cost implication</h2>
        <p class="text-sm text-gray-600">
          {{ disputeData.costImplication }}
        </p>
      </div>
      <div>
        <h2 class="text-base">Download Files</h2>
        <a
          v-for="(file, i) in disputeData.files"
          :key="i"
          :href="file"
          target="_blank"
          class="text-sm text-gray-600"
        >
          Download
        </a>
      </div>

      <div>
        <div class="steps-action">
          <a-button type="primary" @click="disputeDrawerVisible = false">
            Ok
          </a-button>
        </div>
      </div>
    </a-drawer>

    <div v-if="disputeData === {}">
      <a-spin />
    </div>

    <div
      v-else
      id="chat-area"
      class="grid-ish-chat bg-white rounded-md shadow-md h-screen"
    >
      <div
        class="
          chat-header
          py-3
          px-2
          border-b border-gray-400
          w-full
          flex
          items-center
          justify-between
        "
      >
        <div class="grid md:grid-cols-2">
          <div>
            <h2>
              Title
              {{ disputeData.title }}
            </h2>
            <h2>
              Compliant Name:
              {{
                disputeData.complainant ? disputeData.complainant.firstName : ''
              }}
              {{
                disputeData.complainant ? disputeData.complainant.lastName : ''
              }}
            </h2>
            <h3>
              Compliant Email:
              {{ disputeData.complainant ? disputeData.complainant.email : '' }}
            </h3>
          </div>
          <div>
            <h2>Respondent Name: {{ disputeData.respondentName }}</h2>
            <h3>Respondent Email: {{ disputeData.respondentEmail }}</h3>
          </div>
          <div>
            <p>Description: {{ disputeData.description }}</p>
          </div>
          <div>
            <a-button type="primary" @click="disputeDrawerVisible = true"
              >View Dispute Data</a-button
            >
          </div>
        </div>
        <!-- <button class="text-xs p-2 bg-gray-800 text-white" @click="getMessages">
          refresh
        </button> -->
      </div>
      <div class="chat-body h-full w-full">
        <div ref="chatForm" class="flex flex-col w-100 py-3 px-2">
          <div
            v-for="msg in messages"
            :key="msg._id"
            :class="`bg-gray-300 text-gray-800 text-sm rounded-full my-1 py-2 px-5 flex w-full flex-col ${
              msg.sender === user._id
                ? 'text-right self-end'
                : 'text-left self-start'
            }`"
          >
            <span class="text-right text-xs text-gray-700 text-right">
              {{ msg.sender.lastName }} |
              {{ new Date(msg.createdAt).toDateString() }} |
              {{ new Date(msg.createdAt).toTimeString().slice(0, 5) }}</span
            >
            <span>{{ msg.message }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabHeaders from '../../../components/typography/TabHeaders.vue'
import disputeApi from '../../../api/dispute'
import messageApi from '../../../api/message'

export default {
  name: 'UserManagement',
  components: { TabHeaders },
  layout: 'userdashboard',
  data() {
    return {
      disputeId: '',
      disputeData: {},
      resolveMessage: '',
      showSelectMediatorModal: true,
      aPartyHasInitiateResolve: false,
      showMessageAboutConfirmation: false,
      messagesForDecline: '',
      disputeDrawerVisible: false,
      initiatedResolveData: {
        initiator: {},
        respondent: {},
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    width() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width
    },
  },
  created() {
    this.disputeId = this.$route.params.disputeId
    this.userState = this.$route.query.state
    this.getDispute()
    this.getMessages()
  },
  methods: {
    scrollToEnd() {
      setTimeout(() => {
        this.$refs.chatForm.scrollTop = this.$refs.chatForm.scrollHeight
      }, 500)
    },
    async getDispute() {
      const req = await disputeApi(this.axios).get({
        disputeId: this.disputeId,
        params: {
          populate: ['respondent', 'complainant'],
        },
      })
      this.disputeData = req.data
    },
    async getMessages() {
      const req = await messageApi(this.axios).get(
        { type: 'all' },
        {
          dispute: this.disputeId,
          limit: 0,
          populate: ['recipient', 'sender'],
        }
      )
      this.messages = req.data.data.reverse()
      // this.scrollToEnd()
    },
  },
}
</script>

<style>
.chat-header {
  grid-area: 'header';
}
.chat-body {
  grid-area: 'body';
  overflow-y: scroll;
}
.chat-form {
  grid-area: 'message';
}
</style>
